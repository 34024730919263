// import library
import "regenerator-runtime";
import AOS from "aos";
import "aos/dist/aos.css";
import "lazysizes";
import "lazysizes/plugins/parent-fit/ls.parent-fit";

// import Swiper bundle with all modules installed
import "../scss/style.scss";
import "../scss/responsive.scss";

//
import App from "./views/app";
import swRegister from "./utils/sw-register";

// Function to update Sign in links after login and redirect based on role when clicked
function updateSignInLinks() {
  const signInMobile = document.getElementById("signInMobile");
  const signInDesktop = document.getElementById("signInDesktop");

  // Periksa apakah token ada di localStorage
  const user = JSON.parse(localStorage.getItem("user"));

  if (user) {
    // Ganti teks Sign in dengan nama pengguna
    const userName = user.username || "User"; // Ganti dengan nama pengguna yang ada

    // Update tampilan untuk mobile dan desktop
    if (signInMobile) {
      signInMobile.innerHTML = userName; // Tampilkan nama pengguna di mobile
    }
    if (signInDesktop) {
      signInDesktop.innerHTML = userName; // Tampilkan nama pengguna di desktop
    }

    // Menambahkan event listener untuk mengarahkan berdasarkan role ketika diklik
    const redirectToDashboard = (event) => {
      // Mencegah link default untuk arahkan ke halaman login
      event.preventDefault();

      if (user.role === "admin") {
        window.location.hash = "#/admin/dashboard"; // Arahkan ke halaman admin dashboard
      } else if (user.role === "user") {
        window.location.hash = "#/user/dashboard"; // Arahkan ke halaman user dashboard
      }
    };

    // Menambahkan event listener untuk mobile
    if (signInMobile) {
      signInMobile.addEventListener("click", redirectToDashboard);
    }

    // Menambahkan event listener untuk desktop
    if (signInDesktop) {
      signInDesktop.addEventListener("click", redirectToDashboard);
    }
  }
}

// Fungsi untuk menangani token kedaluwarsa
function handleTokenExpiration(response) {
  if (response.error && response.message === "Not authorized, token failed") {
    // Hapus semua data dari localStorage
    localStorage.clear();

    // Tampilkan pesan kepada pengguna
    alert("Sesi Anda telah berakhir. Silakan login kembali.");

    // Arahkan pengguna ke halaman login
    window.location.href = "/login"; // Sesuaikan dengan rute login Anda
  }
}

const app = new App({
  content: document.querySelector("#mainContent"),
});

window.addEventListener("hashchange", () => {
  app.renderPage();
});

window.addEventListener("load", () => {
  updateSignInLinks();
  app.renderPage();
  swRegister();

  AOS.init({
    duration: 1000,
    easing: "ease-in-out",
    once: true,
    mirror: false,
  });

  loader.style.animation = "fadeOut 1.5s ease forwards";
  loader.addEventListener("animationend", () => {
    loader.style.display = "none";
  });
});

// Fungsi yang menghandle API response, pastikan token valid
async function fetchWithAuth(url, options = {}) {
  const response = await fetch(url, options);

  // Cek jika respons statusnya gagal dan token expired
  const data = await response.json();
  handleTokenExpiration(data); // Cek apakah token kadaluarsa

  return data; // Kembalikan data untuk digunakan lebih lanjut
}

import CONFIG from "./config";

const API_ENDPOINT = {
  /* auth verification */
  LOGIN: `${CONFIG.BASE_URL}auth/login`,
  REGISTER: `${CONFIG.BASE_URL}auth/register`,
  USER_PROFILE: `${CONFIG.BASE_URL}auth/profile`,
  GET_ALL_USERS: `${CONFIG.BASE_URL}auth/users`,

  LANDING: `${CONFIG.BASE_URL}landing`,

  /* activities */
  ACT: `${CONFIG.BASE_URL}activities`,
  DETAIL_ACT: (id) => `${CONFIG.BASE_URL}activities/${id}`,

  /* media partner */
  MP: `${CONFIG.BASE_URL}mediaPartners`,
  DETAIL_MP: (id) => `${CONFIG.BASE_URL}mediaPartners/${id}`,

  /* support */
  SP: `${CONFIG.BASE_URL}supports`,

  /* profile */
  PROFILE: `${CONFIG.BASE_URL}profiles`,
  DETAIL_PROFILE: (id) => `${CONFIG.BASE_URL}profiles/${id}`,

  /* event */
  EVENT: `${CONFIG.BASE_URL}events`,
  DETAIL_EVENT: (id) => `${CONFIG.BASE_URL}events/${id}`,

  /* payment */
  PAYMENT: `${CONFIG.BASE_URL}payments`,
  DETAIL_PAYMENT: (id) => `${CONFIG.BASE_URL}payments/${id}`,

  /* transaksi */
  ORDER: `${CONFIG.BASE_URL}orders`,
  ORDER_DETAIL: (id) => `${CONFIG.BASE_URL}orders/${id}`,
};

export default API_ENDPOINT;

import API_ENDPOINT from "../globals/api-endpoint";

class LandingDbSource {
  static async LandingCrew() {
    try {
      const response = await fetch(API_ENDPOINT.LANDING);
      const responseJson = await response.json();

      if (responseJson.landing.length > 0) {
        //ganti restaurants dengan variable yang akan di buat nanti, biasanya berupa variable data
        return responseJson.landing;
      }

      return [];
    } catch (error) {
      return [];
    }
  }

  static async LandingAct() {
    try {
      const response = await fetch(API_ENDPOINT.ACT);
      const responseJson = await response.json();
      // console.log(responseJson);

      if (responseJson.activity.length > 0) {
        return responseJson.activity;
      }
      return [];
    } catch (error) {
      return [];
    }
  }

  static async LandingMP() {
    try {
      const response = await fetch(API_ENDPOINT.MP);
      const responseJson = await response.json();

      if (responseJson.mediaPartner.length > 0) {
        return responseJson.mediaPartner;
      }
      return [];
    } catch (error) {
      return [];
    }
  }

  static async LandingSP() {
    try {
      const response = await fetch(API_ENDPOINT.SP);
      const responseJson = await response.json();

      if (responseJson.support.length > 0) {
        return responseJson.support;
      }
      return [];
    } catch (error) {
      return [];
    }
  }

  static async SourceProfile() {
    try {
      const response = await fetch(API_ENDPOINT.PROFILE);
      const responseJson = await response.json();

      if (responseJson.profile.length > 0) {
        return responseJson.profile;
      }
      return [];
    } catch (error) {
      return [];
    }
  }

  static async LandingEvents() {
    try {
      const response = await fetch(API_ENDPOINT.EVENT);
      const responseJson = await response.json();

      if (responseJson.events.length > 0) {
        return responseJson.events;
      }
      return [];
    } catch (error) {
      return [];
    }
  }

  static async AllPayment() {
    try {
      const response = await fetch(`${API_ENDPOINT.PAYMENT}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      const responseJson = await response.json();

      if (responseJson.payment.length > 0) {
        return responseJson.payment;
      }

      return [];
    } catch (error) {
      return [];
    }
  }

  static async paymentById() {
    try {
      const response = await fetch(`${API_ENDPOINT.DETAIL_PAYMENT}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      const responseJson = await response.json();

      if (responseJson.payment.length > 0) {
        return responseJson.payment;
      }

      return [];
    } catch (error) {
      return [];
    }
  }

  static async allOrder(status = "") {
    try {
      const url = status
        ? `${API_ENDPOINT.ORDER}/all?status=${status}`
        : `${API_ENDPOINT.ORDER}/all`;

      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      const responseJson = await response.json();

      if (responseJson.orders.length > 0) {
        return responseJson.orders;
      }

      return [];
    } catch (error) {
      console.error("Error fetching orders:", error);
      return [];
    }
  }

  static async allOrderUser(status = "") {
    try {
      const url = status
        ? `${API_ENDPOINT.ORDER}?status=${status}`
        : `${API_ENDPOINT.ORDER}`;

      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      const responseJson = await response.json();

      if (responseJson.orders.length > 0) {
        return responseJson.orders;
      }

      return [];
    } catch (error) {
      console.error("Error fetching orders:", error);
      return [];
    }
  }

  static async detailProfile(id) {
    try {
      const response = await fetch(`${API_ENDPOINT.PROFILE}/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      const responseJson = await response.json();

      if (responseJson.error) {
        window.history.back();
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: responseJson.message,
        });
        return null;
      }

      return responseJson;
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Terjadi kesalahan",
        text: "Terjadi kesalahan saat memuat data.",
      });
      window.history.back();
      return null;
    }
  }

  static async detailActivity(id) {
    try {
      const response = await fetch(`${API_ENDPOINT.ACT}/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      const responseJson = await response.json();

      if (responseJson.error) {
        window.history.back();
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: responseJson.message,
        });
        return null;
      }

      return responseJson;
    } catch (error) {
      window.history.back();
      Swal.fire({
        icon: "error",
        title: "Terjadi kesalahan",
        text: "Terjadi kesalahan saat memuat data.",
      });
      return null;
    }
  }

  static async detailMediaPartner(id) {
    try {
      const response = await fetch(`${API_ENDPOINT.DETAIL_MP(id)}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      const responseJson = await response.json();

      if (responseJson.error) {
        window.history.back();
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: responseJson.message,
        });
        return null;
      }

      return responseJson;
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Terjadi kesalahan",
        text: "Terjadi kesalahan saat memuat data.",
      });
      window.history.back();
      return null;
    }
  }

  static async detailEvent(id) {
    try {
      const response = await fetch(`${API_ENDPOINT.DETAIL_EVENT(id)}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      const responseJson = await response.json();

      

      if (responseJson.error) {
        window.history.back();
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: responseJson.message,
        });
        return null;
      }

      return responseJson;
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Terjadi kesalahan",
        text: "Terjadi kesalahan saat memuat data.",
      });
      window.history.back();
      return null;
    }
  }

  static async detailOrder(id) {
    try {
      // Gunakan ORDER_DETAIL sesuai definisi di API_ENDPOINT
      const response = await fetch(API_ENDPOINT.ORDER_DETAIL(id), {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      const responseJson = await response.json();

      if (responseJson.error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: responseJson.message,
        });
        window.history.back();
        return null;
      }

      return responseJson.order;
    } catch (error) {
      console.error("Error fetching order detail:", error);
      Swal.fire({
        icon: "error",
        title: "Terjadi kesalahan",
        text: "Terjadi kesalahan saat memuat data.",
      });
      window.history.back();
      return null;
    }
  }

  /**
   * Tampilkan loader
   */
}

export default LandingDbSource;

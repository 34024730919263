const CONFIG = {
  /*
  LANDING
  */
  // BASE_URL: "http://localhost:3000/",
  BASE_URL: "https://api.ikscrew.com/",

  /*
  LANDING
  */
  DEFAULT_LANGUAGE: "en-us",
  CACHE_NAME: new Date().toISOString(),
  DATABASE_NAME: "iks-db",
  DATABASE_VERSION: 1,
  OBJECT_STORE_NAME: "IKS",
};

export default CONFIG;
